import { useMemo } from "react";
import { View } from "react-native";
import { makeStyles } from "@rneui/themed";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { DateTime } from "luxon";

import ScreenContainer from "../../components/ui/ScreenContainer";
import Text from "../../components/ui/Text";

import Spacing from "../../components/ui/Spacing";
import AppointmentCard from "../../components/ui/banners/AppointmentCard";
import Button from "../../components/ui/Button";

import { DashboardStackParamList } from "../../navigation/DashboardStackNavigator";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import LocalizedStrings from "../../helpers/LocalizedStrings";
import { useGetPatientCalendarEventsQuery } from "common/services/CalendarService";
import useGetAuthenticatedMember from "common/hooks/useGetAuthenticatedMember";
import CalendarEventResponseType from "common/types/Calendaring/CalendarEventResponseType";
import ActivityIndicator from "../../components/ui/ActivityIndicator";
import { FlatGrid } from "react-native-super-grid";

type ScreenProp = NavigationProp<DashboardStackParamList>;

const RenderHeader = () => {
  const textStyles = useTextStyles();
  const styles = useStyles();
  return (
    <View style={styles.container}>
      <Spacing vertical={4} />
      <Text h3 style={textStyles.colorDarkGreyBlue}>
        {LocalizedStrings.screens.myAppointments.text1}
      </Text>
      <Spacing vertical={2} />
      <Text body style={textStyles.colorGreyBlue}>
        {LocalizedStrings.screens.myAppointments.text2}
      </Text>

      <Spacing vertical={4} />
    </View>
  );
};

const RenderItem = ({
  appointment,
  index
}: {
  appointment: CalendarEventResponseType;
  index: number;
}) => {
  return (
    <AppointmentCard
      appointment={appointment}
      canConfirmOrReschedule={index <= 1}
    />
  );
};

const RenderFooter = () => {
  const navigation = useNavigation<ScreenProp>();
  const textStyles = useTextStyles();
  return (
    <>
      <Spacing vertical={4} />
      <Text h4 style={textStyles.colorDarkGreyBlue}>
        {LocalizedStrings.screens.myAppointments.footerText}
      </Text>
      <Spacing vertical={2} />
      <Button
        title={LocalizedStrings.screens.myAppointments.bookAppointment}
        onPress={() => navigation.navigate("YourCareTeam")}
      />
      <Spacing vertical={4} />
    </>
  );
};

const RenderSeparator = () => <Spacing vertical={4} />;

const ListHeaderComponent = () => <RenderHeader />;
const ListFooterComponent = () => <RenderFooter />;

const MyAppointmentsScreen = () => {
  const textStyles = useTextStyles();
  const now = useMemo(() => {
    return DateTime.now();
  }, []);

  const { data: member, isLoading: memberLoading } =
    useGetAuthenticatedMember();

  const { data, isLoading: appointmentLoading } =
    useGetPatientCalendarEventsQuery(
      {
        patient_id: member?.patient.patient_id,
        startdate: now.startOf("day"),
        enddate: now.plus({ days: 89 }).endOf("day")
      },
      { skip: member === undefined }
    );

  const isLoading = memberLoading || appointmentLoading;

  return (
    <ScreenContainer>
      <FlatGrid<CalendarEventResponseType>
        data={data}
        keyExtractor={(item) => item.event_id}
        itemDimension={300}
        ListHeaderComponent={ListHeaderComponent}
        renderItem={({ item, index }) => (
          <RenderItem appointment={item} index={index} />
        )}
        //ListFooterComponent={ListFooterComponent}
        ItemSeparatorComponent={RenderSeparator}
        ListEmptyComponent={() => {
          return isLoading ? (
            <ActivityIndicator />
          ) : (
            <Text body style={textStyles.colorGreyBlue}>
              {LocalizedStrings.screens.myAppointments.noData}
            </Text>
          );
        }}
      />
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginHorizontal: 20
    }
  };
});

export default MyAppointmentsScreen;
