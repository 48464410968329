import { useMemo, useState } from "react";
import { SectionList, View, Image } from "react-native";
import { makeStyles, useTheme } from "@rneui/themed";

import { DateTime } from "luxon";
import Feather from "react-native-vector-icons/Feather";

import { NavigationProp, useNavigation } from "@react-navigation/native";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import useGetAuthenticatedMember from "common/hooks/useGetAuthenticatedMember";
import {
  getErrorMessage,
  getNameOrUsername,
  maskPhoneNumber
} from "common/helpers/helpers";
import { useGetPatientAvailabilityRescheduleEventQuery } from "common/services/PanelManagementService";

import ScreenContainer from "../../components/ui/ScreenContainer";
import Text from "../../components/ui/Text";
import Spacing from "../../components/ui/Spacing";
import { Alert_closeAll, Alert_show } from "common/helpers/AlertHelper";
import { DashboardStackParamList } from "../../navigation/DashboardStackNavigator";
import { useAppDispatch } from "common/redux";
import { staffHealthWorker } from "../../assets/common";
import ActivityIndicator from "../../components/ui/ActivityIndicator";
import TouchableOpacity from "../../components/ui/TouchableOpacity";
import UserTypeInner from "common/types/UserTypeInner";
import LocalizedStrings from "../../helpers/LocalizedStrings";
import IconButton from "../../components/ui/IconButton";

type ScreenProp = NavigationProp<DashboardStackParamList, "BookAppointment">;

const ListHeader = ({
  isReschedule,
  carer,
  role,
  onAllNursesEnabled,
  allNurses
}) => {
  const { theme } = useTheme();
  const styles = useStyles();
  const textStyles = useTextStyles();
  const dispatch = useAppDispatch();

  return (
    <View>
      <Spacing vertical={4} />
      {isReschedule === false && (
        <View style={styles.row}>
          <Image style={styles.avatar} source={staffHealthWorker} />
          <Spacing horizontal={4} />
          <View>
            <Text h4 style={textStyles.colorDarkGreyBlue}>
              {getNameOrUsername(carer)}
            </Text>
            <Spacing vertical={1} />
            <Text capsSmall style={textStyles.colorDarkGreyBlue}>
              {role}
            </Text>
            <Spacing vertical={2} />
            <Text body style={textStyles.colorDarkGreyBlue}>
              <Feather
                name="phone"
                color={theme.colors.darkGreyBlue}
                size={16}
              />{" "}
              {maskPhoneNumber(carer?.phone)}
            </Text>
          </View>
        </View>
      )}
      {isReschedule && (
        <Text h3 style={textStyles.colorDarkGreyBlue}>
          {LocalizedStrings.screens.bookAppointment.selectDate.replace(
            "{{DOCTOR_NAME}}",
            getNameOrUsername(carer)
          )}
        </Text>
      )}

      {isReschedule === false && (
        <>
          <Spacing vertical={4} />
          <Text body style={textStyles.colorGrey0}>
            {LocalizedStrings.screens.bookAppointment.listHeader}
          </Text>
        </>
      )}

      <Spacing vertical={2} />

      {!allNurses && (
        <View style={styles.bannerContainer}>
          <Text body style={[textStyles.colorGreyBlue, styles.flex1]}>
            {LocalizedStrings.screens.bookAppointment.moreTimeBanner}
          </Text>
          <IconButton
            icon={"arrow-right"}
            iconColor={theme.colors.white}
            color={theme.colors.tealBlue}
            onPress={() => {
              Alert_show({
                dispatch,
                title: LocalizedStrings.screens.bookAppointment.moreTimeTitle,
                content:
                  LocalizedStrings.screens.bookAppointment.moreTimeContent,
                size: "large",
                buttons: [
                  {
                    text: LocalizedStrings.screens.bookAppointment
                      .moreTimeButton,
                    onPress: () => {
                      onAllNursesEnabled();
                      Alert_closeAll(dispatch);
                    }
                  },
                  {
                    text: LocalizedStrings.common.noGoBack,
                    style: "cancel"
                  }
                ]
              });
            }}
          />
        </View>
      )}

      <Spacing vertical={2} />
    </View>
  );
};

const ListFooter = ({ carer }) => {
  const styles = useStyles();
  const textStyles = useTextStyles();
  const navigation = useNavigation<ScreenProp>();

  return (
    <View>
      <Spacing vertical={2} />
      <Text bodySmall style={[textStyles.colorGreyBlue, styles.alignCenter]}>
        {LocalizedStrings.screens.bookAppointment.needFasterResponse}
      </Text>
      {
        <TouchableOpacity onPress={() => navigation.navigate("Chat")}>
          <Text link style={[textStyles.colorTealBlue, styles.alignCenter]}>
            {LocalizedStrings.screens.bookAppointment.sendAMessage.replace(
              "{{NAME}}",
              carer.first
            )}
          </Text>
        </TouchableOpacity>
      }
      <Spacing vertical={4} />
    </View>
  );
};

const ListSectionSeparator = () => <Spacing vertical={4} />;
const ListItemSeparator = () => <Spacing vertical={2} />;
const RenderItem =
  ({ event_id, carer }: { event_id: string; carer: UserTypeInner }) =>
  ({ item }) => {
    const styles = useStyles();
    const textStyles = useTextStyles();
    const navigation = useNavigation();

    const { start, end } = item;

    const dateStart = DateTime.fromISO(start);
    const dateEnd = DateTime.fromISO(end);

    const buttonTitle =
      dateStart.toFormat("hh:mm a") + " - " + dateEnd.toFormat("hh:mm a");

    const isOtherCarer = carer.user_id !== item.staff.staff_id;

    return (
      <TouchableOpacity
        style={styles.listItemContainer}
        onPress={() => {
          navigation.navigate("BookAppointmentConfirmation", {
            staff_id: item.staff.staff_id,
            event_id: event_id,
            startDate: item.start,
            endDate: item.end,
            staffName: getNameOrUsername(item.staff)
          });
        }}
      >
        {isOtherCarer && (
          <Text
            capsSmall
            style={[
              textStyles.textAlignCenter,
              textStyles.colorDarkGreyBlue,
              styles.listItemStaffText
            ]}
          >
            {LocalizedStrings.screens.bookAppointment.differentNurse}
          </Text>
        )}
        <Text
          caps
          style={[textStyles.textAlignCenter, textStyles.colorDarkGreyBlue]}
        >
          {buttonTitle}
        </Text>
      </TouchableOpacity>
    );
  };

const ListHeaderComponent = (
  allNurses: boolean,
  isReschedule: boolean,
  carer: UserTypeInner,
  role: string,
  onAllNursesEnabled: () => void
) => (
  <ListHeader
    allNurses={allNurses}
    isReschedule={isReschedule}
    carer={carer}
    role={role}
    onAllNursesEnabled={onAllNursesEnabled}
  />
);

const ListFooterComponent = (carer: UserTypeInner) => (
  <ListFooter carer={carer} />
);

const BookAppointmentScreen = ({ route }) => {
  const styles = useStyles();
  const textStyles = useTextStyles();

  const { isReschedule, event_id } = route.params;

  const [allNurses, setAllNurses] = useState<boolean>(false);

  const {
    data,
    isFetching: isDataLoading,
    error: errorData
  } = useGetPatientAvailabilityRescheduleEventQuery({
    event_id,
    all_nurses: allNurses
  });

  const processedData = useMemo(() => {
    if (data === undefined) return;

    let groupedData = {};
    data.forEach((item) => {
      const luxonDate = DateTime.fromISO(item.start);
      const isoDateOnly = luxonDate.toISODate();
      if (groupedData[isoDateOnly] === undefined) {
        groupedData[isoDateOnly] = [];
      }
      groupedData[isoDateOnly].push(item);
    });

    const sortedKeys = Object.keys(groupedData).sort((a, b) => {
      return DateTime.fromISO(a).diff(DateTime.fromISO(b), "seconds").seconds;
    });

    return sortedKeys.map((item) => {
      return {
        title: DateTime.fromISO(item).toFormat("DDD"),
        data: groupedData[item]
      };
    });
  }, [data]);

  const {
    data: patient,
    isLoading: isMemberLoading,
    error: errorMember
  } = useGetAuthenticatedMember(false, [
    MemberLinkedEntitiesEnum.NURSE,
    MemberLinkedEntitiesEnum.PROVIDER
  ]);

  const { carer, role } = useMemo(() => {
    if (patient?.assigned_nurse?.user_id)
      return { carer: patient?.assigned_nurse, role: "NURSE" };
    else if (patient?.patient_provider?.user_id)
      return { carer: patient?.patient_provider, role: "PROVIDER" };
    else return {};
  }, [patient]);

  const isLoading = isMemberLoading || isDataLoading;
  const error = errorMember ?? errorData;

  return (
    <ScreenContainer>
      {carer === undefined && isLoading === false && (
        <View style={[styles.flex1, styles.center]}>
          <Text h4 style={textStyles.colorDarkGreyBlue}>
            {LocalizedStrings.screens.bookAppointment.noCarersFound}
          </Text>
        </View>
      )}

      {isLoading && <ActivityIndicator style={styles.flex1} />}

      {processedData && carer !== undefined && isLoading === false && (
        <SectionList
          contentContainerStyle={styles.container}
          sections={processedData}
          keyExtractor={(item, index) => item + index}
          renderItem={RenderItem({ event_id, carer })}
          ListHeaderComponent={ListHeaderComponent(
            allNurses,
            isReschedule,
            carer,
            role,
            () => setAllNurses(true)
          )}
          //ListFooterComponent={ListFooterComponent(carer)}
          SectionSeparatorComponent={ListSectionSeparator}
          ItemSeparatorComponent={ListItemSeparator}
          renderSectionHeader={({ section: { title } }) => (
            <Text bodySmall style={textStyles.colorGreyBlue}>
              {title}
            </Text>
          )}
        />
      )}

      {error && (
        <Text style={[styles.container, textStyles.colorError]}>
          {getErrorMessage(error)}
        </Text>
      )}
    </ScreenContainer>
  );
};

const AVATAR_SIZE = 128;

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginHorizontal: 20
    },
    listItemContainer: {
      borderWidth: 1,
      borderColor: theme.colors.tealBlue,
      borderRadius: 20,
      alignItems: "center",
      paddingVertical: 10,
      gap: 5
    },
    listItemStaffText: {
      borderWidth: 1,
      borderColor: theme.colors.darkGreyBlue,
      borderRadius: 13,
      paddingVertical: 3,
      paddingHorizontal: 5
    },
    avatar: {
      width: AVATAR_SIZE,
      height: AVATAR_SIZE,
      borderRadius: AVATAR_SIZE / 8
    },
    row: {
      flexDirection: "row",
      alignItems: "center"
    },
    alignCenter: {
      alignSelf: "center"
    },
    center: {
      justifyContent: "center",
      alignItems: "center"
    },
    flex1: {
      flex: 1
    },
    bannerContainer: {
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: "#FFFAEB",
      borderColor: theme.colors.grey3,
      borderWidth: 1,
      borderRadius: 15,
      padding: 15,
      gap: 10
    }
  };
});

export default BookAppointmentScreen;
