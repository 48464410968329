import React, { useMemo } from "react";
import {
  CreateThemeOptions,
  ThemeMode,
  ThemeProvider,
  createTheme
} from "@rneui/themed";

import useGetCurrentColorScheme from "../hooks/useGetCurrentColorScheme";

// https://reactnativeelements.com/docs/customization/theme_object

const createThemeWithMode: (mode: ThemeMode) => CreateThemeOptions = (
  mode: ThemeMode
) => {
  return createTheme({
    components: {
      Button: (props) => {
        const { type = "solid", color = "primary" } = props;

        const darkGreyBlue = mode === "light" ? "#313F4D" : "#abbed1";
        const tealBlue = "#007EA7";

        if (type === "solid" && color === "primary")
          return {
            buttonStyle: {
              backgroundColor: tealBlue
            }
          };
        if (type === "solid" && color === "white")
          return {
            titleStyle: {
              color: darkGreyBlue
            }
          };

        if (type === "outline" && color === "primary")
          return {
            titleStyle: {
              color: darkGreyBlue
            },
            buttonStyle: {
              borderColor: tealBlue
            }
          };

        if (type === "clear" && color === "primary")
          return {
            titleStyle: {
              color: darkGreyBlue
            }
          };
      },
      Switch: (props) => {
        return {
          color: "#007EA7"
        };
      },
      Card: (props) => {
        return {
          containerStyle: {
            borderRadius: 12,
            borderColor: "#D1E8E4"
          }
        };
      }
    },

    lightColors: {
      primary: "#215D86",
      secondary: "#D1E8E4",
      background: "#FFFFFF",
      black: "#000000", //Dark grey blue
      grey0: "#515151",
      grey1: "#9B9B9B",
      grey2: "#9B9B9B",
      grey3: "#CDCDCD",
      grey4: "#CDCDCD",
      grey5: "#F9FAFB",
      white: "#FFFFFF",
      error: "#E75F5F",
      warning: "#FFBD4A",
      searchBg: "#F1F9FF",

      // Extended colors (Non RNE UI)
      lightGreen: "#D1E8E4",
      tealBlue: "#007EA7",
      tealAAA: "#215D86",
      darkBlue: "#203162",
      greyBlue: "#405A79",
      darkGreyBlue: "#313F4D",
      midBlue2: "#D7EEF2"
    },
    darkColors: {
      primary: "#A6BECF",
      secondary: "#EDF6F4",
      background: "#121212",
      black: "#ffffff",
      grey5: "#FDFDFD",
      grey4: "#EBEBEB",
      grey3: "#EBEBEB",
      grey2: "#D6D6D6",
      grey1: "#D6D6D6",
      grey0: "#B6B6B6",
      white: "#000000",
      error: "#FDC1BC",
      warning: "#FFEDD8",
      searchBg: "#F1F9FF",

      // Extended colors (Non RNE UI)
      lightGreen: "#D1E8E4",
      tealBlue: "#ADCADB",
      tealAAA: "#AABBCD",
      darkBlue: "#A4A6BE",
      greyBlue: "#B3BDC9",
      darkGreyBlue: "#A7ADB4",
      midBlue2: "#D7EEF2"
    },
    mode
  });
};

interface IProps {
  children: React.ReactNode;
}

const ReactNativeTheme = ({ children }: IProps) => {
  const currentColorScheme = useGetCurrentColorScheme();
  const theme = useMemo(() => {
    return createThemeWithMode(currentColorScheme);
  }, [currentColorScheme]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ReactNativeTheme;
