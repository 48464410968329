import { View, Image } from "react-native";
import { makeStyles, useTheme } from "@rneui/themed";
import { FlatGrid } from "react-native-super-grid";

import { getNameOrUsername, unmaskPhoneNumber } from "common/helpers/helpers";
import useGetAuthenticatedMember from "common/hooks/useGetAuthenticatedMember";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";

import ScreenContainer from "../../components/ui/ScreenContainer";
import Text from "../../components/ui/Text";
import useViewStyles from "../../components/ui/styles/useViewStyles";
import IconButton from "../../components/ui/IconButton";
import Spacing from "../../components/ui/Spacing";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { DashboardStackParamList } from "../../navigation/DashboardStackNavigator";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import { staffDoctor, staffHealthWorker } from "../../assets/common";
import { useMemo } from "react";
import ActivityIndicator from "../../components/ui/ActivityIndicator";
import PhoneCallComponent from "../../components/ui/PhoneCallComponent";
import { AnalyticsHelper_logEvent } from "common/helpers/firebase/AnalyticsHelper";
import { Linking_openURL } from "../../helpers/LinkingHelper";
import { dispatch } from "common/redux";
import LocalizedStrings from "../../helpers/LocalizedStrings";
import UserTypeInner from "common/types/UserTypeInner";

type ScreenProp = NavigationProp<DashboardStackParamList, "YourCareTeam">;
interface ActionButtonProps {
  action: string;
  icon: string;
  onPress: () => void;
}

const ActionButton = ({ action, icon, onPress }: ActionButtonProps) => {
  const { theme } = useTheme();
  const textStyles = useTextStyles();
  return (
    <IconButton
      shape="square"
      style={{ flex: 1, maxWidth: 200 }}
      color={theme.colors.tealBlue}
      icon={icon}
      iconColor={theme.colors.white}
      iconSize={32}
      buttonSize={96}
      onPress={onPress}
    >
      <Spacing vertical={2} />

      <Text
        capsSmall
        allowFontScaling={false}
        style={[textStyles.colorWhite, textStyles.textAlignCenter]}
      >
        {action}
      </Text>
    </IconButton>
  );
};

interface ListItemProps {
  user: UserTypeInner;
  role: string;
}

const ListItem = ({ user, role }: ListItemProps) => {
  const navigation = useNavigation<ScreenProp>();
  const styles = useStyles();
  const viewStyles = useViewStyles();
  const textStyles = useTextStyles();

  const staffImage = role === "NURSE" ? staffHealthWorker : staffDoctor;

  if (role === "PROVIDER" && user === undefined) return;

  return (
    <View style={styles.flex1}>
      <View style={[viewStyles.cardContainer, styles.container, styles.flex1]}>
        {user ? (
          <>
            <Spacing vertical={AVATAR_SIZE / 10} />
            <Text
              h4
              style={[textStyles.colorDarkGreyBlue, styles.alignCenter]}
              accessibilityLabel={getNameOrUsername(user)}
            >
              {getNameOrUsername(user)}
            </Text>
            <Spacing vertical={2} />
            <Text
              capsSmall
              style={[textStyles.colorDarkGreyBlue, styles.alignCenter]}
              accessibilityLabel={role}
            >
              {role}
            </Text>
            <Spacing vertical={4} />
          </>
        ) : (
          <View style={styles.noStaffContainer}>
            <Spacing vertical={AVATAR_SIZE / 10} />
            <Text
              h4
              style={[textStyles.colorDarkGreyBlue, textStyles.textAlignCenter]}
            >
              {LocalizedStrings.screens.yourCareTeam.notAssigned.replaceAll(
                "{{ROLE}}",
                role.toLowerCase()
              )}
            </Text>

            <Spacing vertical={4} />
            <View style={styles.buttonRow}>
              <ActionButton
                icon="phone-call"
                action={LocalizedStrings.screens.yourCareTeam.nursingTeam}
                onPress={() => {
                  AnalyticsHelper_logEvent("YourCareTeam_CallNursingTeam");
                  const phoneUrl = `tel:+18889201588`;
                  Linking_openURL(phoneUrl, dispatch);
                }}
              />
            </View>
          </View>
        )}

        {/* hide call/text buttons since zoom contact center is pending */}
        <View style={styles.buttonRow}>
          {role === "NURSE" && user !== undefined && (
            <View style={styles.buttonRow}>
              <ActionButton
                icon="phone-call"
                action={LocalizedStrings.screens.yourCareTeam.myNurse}
                onPress={() => {
                  AnalyticsHelper_logEvent("YourCareTeam_CallingMyNurse");
                  let phone = unmaskPhoneNumber(
                    user.phone || user.mobile || "+1 (888) 920-1588"
                  );
                  // if unmasked number doesn't have country code, add it
                  if (!phone.includes("+1")) phone = "+1" + phone;
                  const phoneUrl = `tel:${phone}`;
                  Linking_openURL(phoneUrl, dispatch);
                }}
              />
              <ActionButton
                icon="phone-call"
                action={LocalizedStrings.screens.yourCareTeam.firstAvailable}
                onPress={() => {
                  AnalyticsHelper_logEvent(
                    "YourCareTeam_CallingFirstAvailable"
                  );
                  const phoneUrl = `tel:+18889201588`;
                  Linking_openURL(phoneUrl, dispatch);
                }}
              />

              {/*<ActionButton
                icon="calendar"
                action={LocalizedStrings.screens.yourCareTeam.book}
                onPress={() => {
                  AnalyticsHelper_logEvent("YourCareTeam_Book");

                  navigation.navigate("BookAppointment", {
                    isReschedule: false
                  });
                }}
              />*/}
            </View>
          )}

          {/*<ActionButton
            icon="message-circle"
            action="TEXT"
            onPress={() => {
              //navigation.navigate('Chat')
              const separator = Platform.OS === 'ios' ? '&' : '?';
              const phone = unmaskPhoneNumber(user.mobile || user.phone);
              const text = 'Hello there I need medical help';
              const encodedText = text.replaceAll(' ', '%20');
              const phoneUrl = `sms:+1${phone}${separator}body=${encodedText}`;
              Linking_openURL(phoneUrl, dispatch);
            }}
          />

          <ActionButton
            icon="calendar"
            action="BOOK"
            onPress={() =>
              navigation.navigate('BookAppointment', {
                isReschedule: false,
                user_id: user.user_id,
              })
            }
          />*/}
        </View>
      </View>
      <View style={styles.doctorImageContainer}>
        <Image
          style={styles.doctorImage}
          source={staffImage}
          resizeMode="contain"
        />
      </View>
    </View>
  );
};

const YourCareTeamScreen = () => {
  const textStyles = useTextStyles();
  const styles = useStyles();
  const { data: patient, isLoading } = useGetAuthenticatedMember(false, [
    MemberLinkedEntitiesEnum.NURSE,
    MemberLinkedEntitiesEnum.PROVIDER
  ]);

  const hasCarers =
    patient?.assigned_nurse !== undefined ||
    patient?.patient_provider !== undefined;

  const gridData = useMemo(() => {
    const gridData = [];
    gridData.push({ user: patient?.assigned_nurse, role: "NURSE" });
    gridData.push({ user: patient?.patient_provider, role: "PROVIDER" });

    return gridData;
  }, [patient]);

  return (
    <ScreenContainer>
      {isLoading === false && !hasCarers && (
        <View style={[styles.flex1, styles.center]}>
          <Text
            h4
            style={[textStyles.colorDarkGreyBlue, textStyles.textAlignCenter]}
            accessibilityLabel={"Your Care Team Message"}
          >
            {LocalizedStrings.screens.yourCareTeam.contactNursingTeam}
          </Text>

          <Spacing vertical={1} />
          <PhoneCallComponent />
        </View>
      )}
      {isLoading === true && <ActivityIndicator style={styles.flex1} />}
      {hasCarers && (
        <FlatGrid
          itemDimension={300}
          data={gridData}
          ListHeaderComponent={() => <Spacing vertical={4} />}
          ListFooterComponent={() => <Spacing vertical={4} />}
          ItemSeparatorComponent={() => <Spacing vertical={4} />}
          renderItem={({ item }) => {
            return <ListItem user={item.user} role={item.role} />;
          }}
        />
      )}
    </ScreenContainer>
  );
};

const AVATAR_SIZE = 128;

const useStyles = makeStyles((theme) => {
  return {
    flex1: {
      flex: 1
    },
    container: {
      paddingHorizontal: 0,
      borderColor: theme.colors.tealBlue,
      marginTop: AVATAR_SIZE / 2
    },
    doctorImageContainer: {
      position: "absolute",
      alignSelf: "center",
      zIndex: 10,
      backgroundColor: theme.colors.background
    },
    doctorImage: {
      width: AVATAR_SIZE,
      height: AVATAR_SIZE,
      borderRadius: AVATAR_SIZE / 8,
      borderColor: theme.colors.tealBlue,
      borderWidth: 1
    },
    noStaffContainer: {
      marginHorizontal: 20
    },
    buttonRow: {
      flex: 1,
      flexDirection: "row",
      gap: 10,
      marginHorizontal: 10,
      justifyContent: "space-evenly"
    },
    alignCenter: {
      alignSelf: "center"
    },
    center: {
      justifyContent: "center",
      alignItems: "center"
    }
  };
});

export default YourCareTeamScreen;
