import React, { useEffect, useMemo, useState } from "react";
import { Divider, makeStyles, useTheme } from "@rneui/themed";
import { View, ScrollView, Platform, RefreshControlProps } from "react-native";
import LinearGradient from "react-native-linear-gradient";
import Feather from "react-native-vector-icons/Feather";
import { DateTime } from "luxon";

import useGetAuthenticatedMember from "common/hooks/useGetAuthenticatedMember";

import ScreenContainer from "../../components/ui/ScreenContainer";
import useViewStyles from "../../components/ui/styles/useViewStyles";
import Text from "../../components/ui/Text";
import ReadingComponent from "../../components/home/ReadingComponent";
import Spacing from "../../components/ui/Spacing";
import YourCareTeam from "../../components/home/YourCareTeam";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";

import { useGetLatestReadingsByMemberQuery } from "common/services/ReadingsService";
import DeviceTrendParam from "common/enums/DeviceTrendParamEnum";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import RefreshControl from "../../components/ui/RefreshControl";
import ActivityIndicator from "../../components/ui/ActivityIndicator";
import { AccessibilityHelper_getAccessibilityProps } from "../../helpers/AccessibilityHelper";
import TouchableOpacity from "../../components/ui/TouchableOpacity";
import { useGetExistingRoomQuery } from "common/services/VideoService";
import { isFalsy } from "common/helpers/helpers";
import Button from "../../components/ui/Button";
import RoomType from "common/types/RoomType";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { DashboardStackParamList } from "../../navigation/DashboardStackNavigator";
import HeaderIconText from "../../components/home/HeaderIconText";
import useAppState from "react-native-appstate-hook";
import { Linking_openURL } from "../../helpers/LinkingHelper";
import { dispatch } from "common/redux";
import LocalizedStrings from "../../helpers/LocalizedStrings";
import AppointmentBanner from "../../components/ui/banners/AppointmentBanner";

interface ScrollViewProps {
  children: React.ReactNode[];
  refreshControl?: React.ReactElement<RefreshControlProps>;
}

const MY_READINGS_SCREEN = "Readings";

//LinearGradient is required (ios only) for bouncing effect on scrollview for proper background handling.
const BounceBackgroundScrollView = ({
  children,
  refreshControl
}: ScrollViewProps) => {
  const { theme } = useTheme();
  const styles = useStyles();

  if (Platform.OS === "ios" && theme.colors.white !== theme.colors.background) {
    return (
      <LinearGradient
        colors={[
          theme.colors.white,
          theme.colors.white,
          theme.colors.background,
          theme.colors.background
        ]}
      >
        <ScrollView
          contentContainerStyle={styles.scrollView}
          refreshControl={refreshControl}
        >
          {children}
        </ScrollView>
      </LinearGradient>
    );
  } else {
    return (
      <ScrollView
        contentContainerStyle={styles.scrollView}
        refreshControl={refreshControl}
      >
        {children}
      </ScrollView>
    );
  }
};

type ScreenProp = NavigationProp<DashboardStackParamList>;
const OngoingCall = ({ room }: { room: RoomType }) => {
  const styles = useStyles();
  const navigation = useNavigation<ScreenProp>();

  const [currentDate, setCurrentDate] = useState<DateTime>(DateTime.now());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(DateTime.now());
    }, 100);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const startedDate = DateTime.fromISO(room.start_datetime);

  const duration = currentDate.diff(startedDate).toFormat("mm:ss");

  return (
    <View>
      <HeaderIconText
        text={`${LocalizedStrings.screens.home.ongoingCall} ${duration}`}
      />

      <Spacing vertical={2} />
      <Button
        title={LocalizedStrings.common.join}
        containerStyle={styles.joinCallButtonContainer}
        buttonStyle={styles.joinCallButton}
        onPress={() => navigation.navigate("VideoCall", { code: room.code })}
      />
    </View>
  );
};

const HomeScreen = ({ navigation }) => {
  const { theme } = useTheme();
  const styles = useStyles();
  const viewStyles = useViewStyles();
  const textStyles = useTextStyles();
  const { appState } = useAppState();

  const { data: patient, isLoading: patientLoading } =
    useGetAuthenticatedMember(false, [
      MemberLinkedEntitiesEnum.PROVIDER,
      MemberLinkedEntitiesEnum.NURSE
    ]);

  const skipExistingRoom = isFalsy(patient) || appState !== "active";
  const { data: existingRoom, refetch: refetchExistingRoom } =
    useGetExistingRoomQuery(
      {
        member_id: patient?.patient?.patient_id
      },
      {
        skip: skipExistingRoom,
        pollingInterval: 30000
      }
    );

  const {
    data: lastReading,
    isFetching: isReadingsFetching,
    isLoading: isReadingsLoading,
    isSuccess,
    refetch: refetchReadings
  } = useGetLatestReadingsByMemberQuery(
    {
      memberId: patient?.patient?.patient_id,
      devices: [DeviceTrendParam.GLUCOSE, DeviceTrendParam.BLOOD_PRESSURE]
    },
    { skip: patient === undefined }
  );

  const lastBPReading = lastReading
    ? lastReading[DeviceTrendParam.BLOOD_PRESSURE]
    : undefined;
  const lastGlucoseReading = lastReading
    ? lastReading[DeviceTrendParam.GLUCOSE]
    : undefined;

  const READINGS_DATA = useMemo(() => {
    const hasBloodPressure = lastBPReading !== undefined;
    const hasGlucose = lastGlucoseReading !== undefined;

    return [
      {
        key: "reading_blood_pressure",
        header: LocalizedStrings.screens.home.readingsBP,
        maxFont: 1.1,
        value: hasBloodPressure
          ? lastBPReading.systolic + "/" + lastBPReading.diastolic
          : undefined,
        measureUnit: "mmHg",
        loading: isReadingsLoading || patientLoading,
        dateTime: hasBloodPressure
          ? DateTime.fromSeconds(lastBPReading.measure_timestamp)
          : undefined,
        onPress: () => {
          navigation.navigate(MY_READINGS_SCREEN, {
            screen: "BloodReadingDetails",
            initial: false
          });
        }
      },
      {
        key: "reading_pulse",
        header: LocalizedStrings.screens.home.readingsPulse,
        maxFont: 1.3,
        value: hasBloodPressure ? lastBPReading.pulse : undefined,
        measureUnit: "bpm",
        loading: isReadingsLoading || patientLoading,
        dateTime: hasBloodPressure
          ? DateTime.fromSeconds(lastBPReading.measure_timestamp)
          : undefined,
        onPress: () => {
          navigation.navigate(MY_READINGS_SCREEN, {
            screen: "BloodReadingDetails",
            initial: false
          });
        }
      },
      {
        key: "reading_glucose",
        header: LocalizedStrings.screens.home.readingsGlucose,
        maxFont: 1.3,
        value: hasGlucose ? lastGlucoseReading.glucose : undefined,
        measureUnit: "mg/dL",
        dateTime: hasGlucose
          ? DateTime.fromSeconds(lastGlucoseReading.measure_timestamp)
          : undefined,
        loading: isReadingsLoading || patientLoading,
        onPress: () => {
          navigation.navigate(MY_READINGS_SCREEN, {
            screen: "GlucoseReadingDetails",
            initial: false
          });
        }
      }
    ];
  }, [lastBPReading, lastGlucoseReading, isReadingsLoading, patientLoading]);

  const handleLearnMore = () => {
    Linking_openURL("https://www.copilotiq.com", dispatch);
  };

  const noReadingsDoneLoading = isSuccess && READINGS_DATA.length === 0;

  const handleRefresh = () => {
    if (patient !== undefined) refetchReadings();
    if (!skipExistingRoom) refetchExistingRoom();
  };

  return (
    <ScreenContainer>
      <BounceBackgroundScrollView
        refreshControl={
          <RefreshControl
            refreshing={isReadingsLoading === false && isReadingsFetching}
            onRefresh={handleRefresh}
          />
        }
      >
        <View style={[styles.headerContainer, viewStyles.navigationBarRounded]}>
          <View style={styles.row}>
            {patientLoading ? (
              <ActivityIndicator style={styles.flex1} size="small" />
            ) : (
              <Text
                h2
                allowFontScaling={false}
                {...AccessibilityHelper_getAccessibilityProps("Member Name")}
                testID="patientName"
              >
                {patient
                  ? `${LocalizedStrings.common.hello}, ${patient.patient.first}!`
                  : " "}
              </Text>
            )}

            {/*<IconButton
              icon="bell"
              onPress={() => navigation.navigate('Notification')}
              type="outline"
              color={theme.colors.darkGreyBlue}
              iconColor={theme.colors.darkGreyBlue}
              iconSize={NOTIFICATION_SIZE / 2}>
              <Badge status="error" containerStyle={styles.badge} />
  </IconButton>*/}
          </View>

          {!noReadingsDoneLoading && (
            <Text
              bodySmall
              style={[styles.textCenter, textStyles.colorGreyBlue]}
            >
              {LocalizedStrings.screens.home.latestReadings}
            </Text>
          )}
          {!noReadingsDoneLoading && (
            <Spacing vertical={READINGS_MARGIN_TOP / 15} />
          )}

          {noReadingsDoneLoading && (
            <>
              <Text body>{LocalizedStrings.screens.home.noReadingsText}</Text>
              <TouchableOpacity onPress={handleLearnMore}>
                <Text>
                  <Text link>{LocalizedStrings.screens.home.learnMore}</Text>{" "}
                  <Feather
                    name="arrow-right"
                    size={16}
                    color={theme.colors.primary}
                  />
                </Text>
              </TouchableOpacity>
            </>
          )}
        </View>

        {!noReadingsDoneLoading && (
          <View style={styles.readingAbsoluteContainer}>
            <View style={styles.readingInnerContainer}>
              {READINGS_DATA.map(
                ({
                  header,
                  value,
                  maxFont,
                  measureUnit,
                  dateTime,
                  key,
                  onPress,
                  loading
                }) => (
                  <ReadingComponent
                    key={key}
                    testID={key}
                    header={header}
                    maxFont={maxFont}
                    value={value}
                    measureUnit={measureUnit}
                    dateTime={dateTime}
                    onPress={onPress}
                    loading={loading}
                  />
                )
              )}
            </View>
          </View>
        )}

        <View
          style={[
            styles.contentContainer,
            !noReadingsDoneLoading && {
              marginTop: READINGS_MARGIN_TOP - 40
            }
          ]}
        >
          {!noReadingsDoneLoading && (
            <View>
              <Text
                bodySmall
                style={[styles.textCenter, textStyles.colorGreyBlue]}
              >
                {LocalizedStrings.screens.home.tapReadingToViewMore}
              </Text>
            </View>
          )}

          <Divider style={styles.divider} color={theme.colors.lightGreen} />

          {existingRoom && <OngoingCall room={existingRoom} />}

          {/*<LandingBanner
            title="DAY 1"
            subTitle="Start your journey"
            description="Review the goals you set with your nurse."
            buttonTitle="My Goals"
            onButtonPress={() => navigation.navigate('MyGoals')}
          />
          <LandingBanner
            variant="secondary"
            title="DAY 2"
            subTitle="Schedule an Appointment"
            description="Check your nurse's availability to schedule an appointment for next week."
            buttonTitle="Book Appointment"
            onButtonPress={() => navigation.navigate('YourCareTeam')}
          />*/}

          <AppointmentBanner patient={patient} />

          <YourCareTeam />
        </View>

        {/* <GoalProgress /> */}

        {/*<View style={styles.contentContainer}>
          <YourCareTeam />
        </View>*/}
      </BounceBackgroundScrollView>
    </ScreenContainer>
  );
};

const NOTIFICATION_SIZE = 32;

const READINGS_MARGIN_TOP = 110;

const MARGIN = 20;

const useStyles = makeStyles((theme) => {
  return {
    flex1: {
      flex: 1
    },
    container: {
      backgroundColor: theme.colors.white
    },
    scrollView: {
      backgroundColor: theme.colors.background
    },
    headerContainer: {
      backgroundColor: theme.colors.white,
      padding: MARGIN,
      gap: MARGIN
    },
    badge: {
      position: "absolute",
      top: NOTIFICATION_SIZE / 8,
      right: NOTIFICATION_SIZE / 8
    },
    row: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      height: 40
    },
    readingAbsoluteContainer: {
      position: "absolute",
      left: 0,
      right: 0,
      top: READINGS_MARGIN_TOP
    },
    readingInnerContainer: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      gap: MARGIN,
      marginHorizontal: MARGIN
    },
    contentContainer: {
      margin: MARGIN,
      gap: MARGIN
    },
    divider: {
      width: 100,
      alignSelf: "center"
    },
    textCenter: {
      alignSelf: "center"
    },
    joinCallButtonContainer: {
      maxWidth: 500
    },
    joinCallButton: {
      backgroundColor: theme.colors.error
    }
  };
});

export default HomeScreen;
