import { ScrollView, View } from "react-native";
import { makeStyles } from "@rneui/themed";

import { DateTime } from "luxon";

import ScreenContainer from "../../components/ui/ScreenContainer";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import Text from "../../components/ui/Text";
import LocalizedStrings from "../../helpers/LocalizedStrings";

import Spacing from "../../components/ui/Spacing";
import Button from "../../components/ui/Button";
import { useEffect, useState } from "react";
import { CalendarHelper_addEvent } from "../../helpers/CalendarHelper";
import { useAppDispatch } from "common/redux";
import { usePatientRescheduleCalendarEventMutation } from "common/services/CalendarService";
import { useHideTabBar } from "../../navigation/NavigationUtils";
import useScreenType, { ScreenTypeEnum } from "../../hooks/useScreenType";
import ConfirmAppointmentBox from "../../components/ui/ConfirmAppointmentBox";

const BookAppointmentConfirmationScreen = ({ navigation, route }) => {
  const textStyles = useTextStyles();
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const { type } = useScreenType();

  const { event_id, startDate, endDate, staffName, staff_id } = route.params;

  useHideTabBar(navigation, type !== ScreenTypeEnum.PHONE);

  const [isConfirmed, setConfirmed] = useState<boolean>(false);

  const [
    patientRescheduleCalendarEvent,
    { isSuccess, isLoading: isMutationLoading }
  ] = usePatientRescheduleCalendarEventMutation();

  const luxonStartDate = DateTime.fromISO(startDate);
  const luxonEndDate = DateTime.fromISO(endDate);

  useEffect(() => {
    if (isSuccess) setConfirmed(isSuccess);
  }, [isSuccess]);

  const handleConfirm = () => {
    patientRescheduleCalendarEvent({
      event_id,
      staff_id,
      start_date: luxonStartDate,
      end_date: luxonEndDate
    });
  };

  const goBack = () => {
    navigation.goBack();
    // We need to go back 2 times in order to go to skip the reschedule list screen.
    if (isSuccess) navigation.goBack();
  };

  const handleAddToCalendar = () => {
    setTimeout(
      () =>
        CalendarHelper_addEvent({
          title:
            LocalizedStrings.screens.bookAppointment.addToCalendarTitle.replace(
              "{{DOCTOR_NAME}}",
              staffName
            ),
          description:
            LocalizedStrings.screens.bookAppointment.addToCalendarDescription.replace(
              "{{DOCTOR_NAME}}",
              staffName
            ),
          startDate: luxonStartDate,
          endDate: luxonEndDate,
          dispatch,
          onFinish: goBack
        }),
      1000
    );
  };

  return (
    <ScreenContainer>
      <ScrollView>
        <View style={styles.container}>
          <Text h3 style={textStyles.colorDarkGreyBlue}>
            {LocalizedStrings.screens.bookAppointmentConfirmation.header}
          </Text>
          <Spacing vertical={6} />

          <ConfirmAppointmentBox
            startDate={luxonStartDate}
            endDate={luxonEndDate}
            staffName={staffName}
          />

          <Spacing vertical={6} />

          {!isConfirmed && (
            <Button
              title={LocalizedStrings.common.yesConfirm}
              onPress={handleConfirm}
              loading={isMutationLoading}
            />
          )}
          <Spacing vertical={4} />
          <Button
            type="outline"
            disabled={isMutationLoading}
            title={
              isConfirmed
                ? LocalizedStrings.common.goBack
                : LocalizedStrings.common.noGoBack
            }
            onPress={goBack}
          />

          <Spacing vertical={2} />

          {isConfirmed && (
            <Button
              type="clear"
              onPress={handleAddToCalendar}
              title={LocalizedStrings.screens.confirmAppointment.addToCalendar}
            />
          )}
        </View>
      </ScrollView>
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      flex: 1,
      marginHorizontal: 20,
      paddingVertical: 20,
      maxWidth: 400,
      alignSelf: "center"
    }
  };
});

export default BookAppointmentConfirmationScreen;
