import { ScrollView, View } from "react-native";
import { makeStyles, useTheme } from "@rneui/themed";

import { DateTime } from "luxon";
import Feather from "react-native-vector-icons/Feather";

import {
  getErrorMessage,
  getNameOrUsername,
  isFalsy
} from "common/helpers/helpers";

import ScreenContainer from "../../components/ui/ScreenContainer";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import Text from "../../components/ui/Text";
import LocalizedStrings from "../../helpers/LocalizedStrings";

import Spacing from "../../components/ui/Spacing";
import Button from "../../components/ui/Button";
import { useEffect, useState } from "react";
import { CalendarHelper_addEvent } from "../../helpers/CalendarHelper";
import { useAppDispatch } from "common/redux";
import {
  useGetCalendarEventQuery,
  usePatientConfirmAppointmentMutation
} from "common/services/CalendarService";
import useGetAuthenticatedMember from "common/hooks/useGetAuthenticatedMember";
import ActivityIndicator from "../../components/ui/ActivityIndicator";
import useScreenType, { ScreenTypeEnum } from "../../hooks/useScreenType";
import { useHideTabBar } from "../../navigation/NavigationUtils";
import ConfirmAppointmentBox from "../../components/ui/ConfirmAppointmentBox";

const ConfirmAppointmentScreen = ({ navigation, route }) => {
  const { theme } = useTheme();
  const textStyles = useTextStyles();
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const { type } = useScreenType();
  useHideTabBar(navigation, type !== ScreenTypeEnum.PHONE);

  const { data: member } = useGetAuthenticatedMember();
  const { appointment_id } = route.params;

  const [isConfirmed, setConfirmed] = useState<boolean>(false);

  const {
    data: appointment,
    isLoading: isDataLoading,
    isSuccess: isDataSuccess,
    error: dataError
  } = useGetCalendarEventQuery({
    event_id: appointment_id
  });

  const [
    confirmAppointmentMutation,
    { isSuccess, isLoading: isMutationLoading }
  ] = usePatientConfirmAppointmentMutation();

  const startDate = DateTime.fromISO(appointment?.startdate);
  const endDate = DateTime.fromISO(appointment?.enddate);
  const doctorName = getNameOrUsername(appointment?.staff);

  useEffect(() => {
    if (isSuccess) setConfirmed(isSuccess);
  }, [isSuccess]);

  useEffect(() => {
    if (isFalsy(member)) return;

    const attendee = appointment?.attendees?.find(
      (item) => item.attendee_id === member.patient.patient_id
    );
    return setConfirmed(attendee?.confirmed === true);
  }, [appointment, member]);

  const handleCancel = () => {
    navigation.goBack();
  };

  const handleConfirm = () => {
    confirmAppointmentMutation({
      event_id: appointment_id,
      patient_id: member?.patient?.patient_id
    });
  };

  const goBack = () => {
    navigation.goBack();
  };

  const handleAddToCalendar = () => {
    setTimeout(
      () =>
        CalendarHelper_addEvent({
          title:
            LocalizedStrings.screens.bookAppointment.addToCalendarTitle.replace(
              "{{DOCTOR_NAME}}",
              doctorName
            ),
          description:
            LocalizedStrings.screens.bookAppointment.addToCalendarDescription.replace(
              "{{DOCTOR_NAME}}",
              doctorName
            ),
          startDate: startDate,
          endDate: endDate,
          dispatch,
          onFinish: goBack
        }),
      1000
    );
  };

  return (
    <ScreenContainer>
      <ScrollView>
        <View style={styles.container}>
          <Text h3 style={textStyles.colorDarkGreyBlue}>
            {isConfirmed
              ? LocalizedStrings.screens.confirmAppointment.confirmedHeader
              : LocalizedStrings.screens.confirmAppointment.header}
          </Text>
          <Spacing vertical={2} />
          {!isConfirmed && isDataSuccess && (
            <Text body style={textStyles.colorGreyBlue}>
              {LocalizedStrings.screens.confirmAppointment.subHeader}
            </Text>
          )}

          <Spacing vertical={6} />
          {isDataLoading && <ActivityIndicator />}

          {isDataSuccess && (
            <ConfirmAppointmentBox
              startDate={startDate}
              endDate={endDate}
              staffName={doctorName}
            />
          )}

          {dataError && (
            <Text style={textStyles.colorError}>
              {getErrorMessage(dataError)}
            </Text>
          )}

          {isConfirmed && (
            <View style={styles.thankYouContainer}>
              <Feather name="heart" size={32} color={theme.colors.tealAAA} />
              <Spacing horizontal={2} />
              <Text
                caps
                style={[
                  textStyles.colorTealAAA,
                  { flex: 1, textAlign: "left" }
                ]}
              >
                {LocalizedStrings.screens.confirmAppointment.thankYou}
              </Text>
            </View>
          )}
          <Spacing vertical={6} />
          {isDataSuccess && (
            <>
              {!isConfirmed && (
                <Button
                  title={LocalizedStrings.common.yesConfirm}
                  onPress={handleConfirm}
                  loading={isMutationLoading}
                  disabled={isFalsy(member)}
                />
              )}
              <Spacing vertical={4} />
              <Button
                type="outline"
                title={
                  isConfirmed
                    ? LocalizedStrings.screens.confirmAppointment
                        .returnToPreviousScreen
                    : LocalizedStrings.common.noGoBack
                }
                onPress={handleCancel}
              />

              <Spacing vertical={2} />

              {isConfirmed && (
                <Button
                  type="clear"
                  onPress={handleAddToCalendar}
                  title={
                    LocalizedStrings.screens.confirmAppointment.addToCalendar
                  }
                />
              )}
            </>
          )}
        </View>
      </ScrollView>
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginHorizontal: 20,
      paddingVertical: 20,
      maxWidth: 400,
      alignSelf: "center"
    },
    thankYouContainer: {
      borderRadius: 5,
      backgroundColor: "#B1DFFF",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-evenly",
      padding: 10
    }
  };
});

export default ConfirmAppointmentScreen;
